/* header styles */
.navbar-dark {
  background-color: #0a5494;
  border-bottom: 2px solid #ce2c09;
}

.navbar {
  .nav-link {
    color: #f0f0f0 !important;
    opacity: 1 !important;
  }
  .material-icons-outlined {
    vertical-align: top;
  }
}
/* phone styles */

@media screen and (max-width: 576px) {
  a.d-flex {
    display: block !important;
  }
  h1.h5 {
    font-size: 1rem;
    line-height: 1.6rem;
  }
}
