.announcement {
  max-height: 75%;
  overflow: auto;
  width: 100%;
  background-color: #ffffff;
}

.global-alert {
  padding: 1rem;
  margin-bottom: 0;
  border-radius: 0;
}

.announcement .alert-danger {
  color: #c00000;
  background-color: transparent;
  border-bottom: 1px solid #505050;
}

.home-banner {
  height: 340px;
  background: url("homepage.jpg") center;
  background-size: cover;
  margin: 0;
}

.home-heading {
  font-family: Palatino, serif;
  font-size: 1.8rem;
  font-weight: 600;
}

.home-apps {
  a {
    color: #fff;
    text-decoration: none;
  }

  .card-title {
    font-size: 1.2rem;
    color: #202020;
  }

  .app-link {
    &:nth-child(even) span.material-icons-outlined {
      background-color: #ce2c09;
    }
    span.material-icons-outlined {
      background-color: #0a5494;
      padding: 1.2rem;
      border-radius: 12px;
    }

    .material-icons-outlined.xlg:hover {
      background-color: #303030;
    }

    .material-icons-outlined.xlg {
      font-size: 4rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .home-heading {
    font-size: 2.6rem;
    margin: 2rem 0 !important;
  }
}

@media screen and (max-width: 1199px) {
  .navbar-nav .me-3 {
    margin-right: 0.25rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .home-banner {
    height: 460px;
  }
  .home-heading {
    margin: 3rem 0 !important;
  }
}
