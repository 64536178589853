.file-drop {
  border-width: 5px;
  border-radius: 15px;
  border-style: dashed;
  border-color: #b2b2b2;

  & > .file-drop-target {
    border-radius: 10px;
    padding: 16px;
    transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &.file-drop-dragging-over-frame {
      // Hovering over the window with a file
      box-shadow: inset 0 0 8px 0px black;
    }

    &.file-drop-dragging-over-target {
      // Hovering over the input area with a file
      background-color: #d6ecff;
    }
  }
}
