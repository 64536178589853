.rbt {
  &:not(.has-aux) > div {
    position: relative;

    &:first-child::after {
      content: "";
      display: inline-block;
      height: 0.5em;
      width: 0.5em;
      position: absolute;
      right: 0.75em;
      top: 50%;
      transform: translateY(-50%) rotate(135deg);
      border-width: 0.15em 0.15em 0 0;
      border-style: solid;
      border-color: black;
      opacity: 0.5;
      pointer-events: none;
    }

    & > .rbt-input-main {
      padding-right: 2rem;
    }
  }

  & > .rbt-menu {
    & > .dropdown-item {
      color: #212529 !important;

      &:hover {
        background-color: #e9ecef !important;
        color: #1e2125 !important;
      }

      &:active {
        color: #fff !important;
        text-decoration: none !important;
        background-color: #0d6efd !important;
      }
    }
  }

  & > .rbt-aux {
    & > .rbt-close {
      margin-top: 0;

      & > .rbt-close-content {
        display: none !important;
      }
    }
  }
}
