.analytics-container {
  display: flex;
  width: 100%;
  height: 100%;
}

// special case for analytics views
.main:has(.analytics-container) {
  overflow: auto;
}

.modal-content {
  &.has-error,
  .has-error {
    border: solid 1px red;
  }
}
